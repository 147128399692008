import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import title from 'images/element/home/staff/staff-title.svg';
import staff1 from 'images/element/home/staff/staff-1.png';
import staff2 from 'images/element/home/staff/staff-2.png';
import staff3 from 'images/element/home/staff/staff-3.png';
import staff4 from 'images/element/home/staff/staff-4.png';
import staff5 from 'images/element/home/staff/staff-5.png';
import staff6 from 'images/element/home/staff/staff-6.png';
import staff7 from 'images/element/home/staff/staff-7.png';
import staff8 from 'images/element/home/staff/staff-8.png';
import staff9 from 'images/element/home/staff/staff-9.png';
import staff10 from 'images/element/home/staff/staff-10.png';

import SeeMoreButton from './SeeMoreButton';

const staffData = [
  { id: 's-01', img: staff1, firstName: 'Nelada', lastName: 'Chanhirankul' },
  { id: 's-02', img: staff2, firstName: 'Apiwat', lastName: 'Supa' },
  { id: 's-03', img: staff3, firstName: 'Banjamas', lastName: 'Sombatsri' },
  { id: 's-04', img: staff4, firstName: 'Chitra', lastName: 'Chankrajang' },
  { id: 's-05', img: staff5, firstName: 'Patinya', lastName: 'Ngowhuad' },
  {
    id: 's-06',
    img: staff6,
    firstName: 'Pattaramon',
    lastName: 'Krungkraijak',
  },
  { id: 's-07', img: staff7, firstName: 'Phensuphang', lastName: 'Dontuam' },
  {
    id: 's-08',
    img: staff8,
    firstName: 'Pornnapas',
    lastName: 'Chairatnanthakul',
  },
  {
    id: 's-09',
    img: staff9,
    firstName: 'Thanaphiphat',
    lastName: 'Phiphitthanathat',
  },
  { id: 's-10', img: staff10, firstName: 'Thapanun', lastName: 'Sangtipkanya' },
];

const StaffList = ({ contentSize }) => {
  const [slideHeight, setSlideHeight] = React.useState(0);
  const [slidesToShow, setSlidesToShow] = React.useState(1);

  React.useEffect(() => {
    const calSlideHeight = () => {
      let height = contentSize.width / 4;

      if (contentSize.width < 800) {
        height = contentSize.width / 2 + 140;
      }
      if (contentSize.width < 500) {
        height = contentSize.width;
      }

      return height + 80;
    };

    setSlideHeight(calSlideHeight());
  }, [contentSize.width]);

  React.useEffect(() => {
    if (contentSize.width < 500) {
      setSlidesToShow(1);
    } else if (contentSize.width < 800) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(4);
    }
  }, [contentSize.width]);

  return (
    <div className="flex flex-col w-full bg-main-gradient overflow-hidden">
      <div className="mt-20">
        <img src={title} alt="title" />
      </div>
      <div className="flex items-center justify-center w-full mt-12">
        <div className="" style={{ width: '99%' }}>
          <Carousel
            swiping
            slidesToShow={slidesToShow}
            cellSpacing={0}
            height={`${slideHeight}px`}
            framePadding="-20px 0 0 0"
            /*
             *slideIndex={slideIndex}
             *afterSlide={index => setSlideIndex(index)}
             */
            defaultControlsConfig={{
              pagingDotsStyle: {
                fill: '#B18739',
                padding: '0 3px',
              },
            }}
            renderCenterLeftControls={control => {
              const disabled = control.currentSlide === control.slideCount - 2;
              return (
                <button
                  disabled={disabled}
                  className="w-10 pb-12"
                  onClick={control.previousSlide}
                >
                  <FontAwesomeIcon
                    className={
                      (disabled
                        ? 'opacity-75 text-gray-500'
                        : 'opacity-100 text-white') + ' text-lg md:text-2xl'
                    }
                    icon={['fas', 'chevron-left']}
                  />
                </button>
              );
            }}
            renderCenterRightControls={control => {
              const disabled = control.currentSlide === control.slideCount - 1;
              return (
                <button
                  disabled={disabled}
                  className="w-10 pb-12"
                  onClick={control.nextSlide}
                >
                  <FontAwesomeIcon
                    className={
                      (disabled
                        ? 'opacity-75 text-gray-500'
                        : 'opacity-100 text-white') + ' text-lg md:text-2xl'
                    }
                    icon={['fas', 'chevron-right']}
                  />
                </button>
              );
            }}
          >
            {staffData.map(staff => {
              return (
                <div
                  key={staff.id}
                  className="flex flex-col items-center justify-center h-full overflow-hidden"
                  style={{ height: slideHeight }}
                >
                  <div className="w-2/3 h-auto md:w-3/4">
                    <img src={staff.img} alt="staff" />
                  </div>
                  <div className="flex flex-col items-center justify-center mt-4 text-white">
                    <div className="font-bold">{staff.firstName}</div>
                    <div className="italic">{staff.lastName}</div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="flex items-center justify-center w-full py-2">
        <SeeMoreButton linkTo="/team" />
      </div>
    </div>
  );
};
StaffList.propTypes = {
  contentSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
StaffList.defaultProps = {
  contentSize: { width: 0, height: 0 },
};
export default StaffList;

/* This is the way to use 2 imgs, circle (svg) and staff person (png)
// import circle from 'images/element/our-staff-circle.svg';
            <div
              className="flex items-center justify-center h-full overflow-hidden bg-blue-400"
              style={{ height: slideHeight }}
            >
              <div className="relative flex items-center justify-center w-3/4 h-auto">
                <img
                  src={circle}
                  alt="circle"
                  className="absolute overflow-hidden rounded-full"
                  style={{ background: '#C6CDDA' }}
                />
                <img
                  src={staff1}
                  alt="staff"
                  className="absolute"
                  style={{ width: '90%' }}
                />
              </div>
            </div>

*/
