import React from 'react';

import horizontalLine from 'images/element/horizontal-line.svg';
import aboutUsCharacter from 'images/element/home/aboutus/character.png';

const Aboutus = () => {
  return (
    <div className="py-12 bg-white grid grid-cols-1 lg:grid-cols-3">
      <div className="">
        <div className="flex items-end justify-center h-full pt-2">
          <img
            src={aboutUsCharacter}
            alt="aboutus character"
            className="object-contain w-1/2 lg:h-auto lg:w-4/5"
          />
        </div>
      </div>

      <div className="flex flex-col justify-between w-full lg:col-span-2">
        <div className="flex items-center justify-center w-full lg:hidden">
          <img src={horizontalLine} alt="horizontal line" className="w-1/2" />
        </div>

        <div className="flex items-center justify-center pt-6 lg:mt-0 lg:pt-0">
          <div
            className="text-2xl tracking-wide text-right whitespace-no-wrap md:text-5xl lg:ml-20"
            style={{ color: '#00464E' }}
          >
            ABOUT US
          </div>
          <div className="hidden w-full ml-8 lg:flex">
            <img
              src={horizontalLine}
              alt="horizontal line"
              className="w-full"
            />
          </div>
        </div>

        <div
          className="flex flex-col w-full px-10 py-6 lg:px-20"
          style={{ color: '#3E8180' }}
        >
          <div className="w-full text-lg text-center lg:text-left lg:text-xl">
            {`เราบริการให้คำปรึกษาด้านการวางแผนทางการเงิน ประกอบไปด้วย`}
          </div>
          <ul className="my-4 leading-relaxed text-left text-sm md:text-base list-disc list-inside">
            <li>ด้านการวางแผนหนี้สิน (Debt Management)</li>
            <li>ด้านการวางแผนความเสี่ยง (Risk Management)</li>
            <li>ด้านการวางแผนภาษี (Tax Planning)</li>
            <li>ด้านการวางแผนการลงทุน (Investment Planning)</li>
            <li>ด้านการวางแผนเกษียณ (Retirement Planning)</li>
            <li>ด้านการวางแผนมรดก (Estate Planning)</li>
          </ul>
          <div className="w-full text-lg leading-relaxed text-center lg:text-xl lg:text-left">{`
ทางเรามีทีมงานที่เชี่ยวชาญในด้านวางแผนการเงินแบบครบวงจรกว่า 15 ท่าน และทีมงานที่เชี่ยวชาญในด้านวางแผนภาษี ทีมงานทางด้านกฏหมายที่มีประสบการณ์และพร้อมให้คำแนะนำที่เป็นประโยชน์กับคุณลูกค้าอีกกว่า 10 ท่าน ตลอดระยะเวลาในการทำงานที่ผ่านมาทางเราได้รับความไว้วางใจจากคุณลูกค้าให้ดูแลภาษีนิติบุคคลกว่า 20 แห่ง และดูแลภาษีคุณลูกค้าที่เป็นบุคคลธรรมดาอีกว่า 500 ราย
          `}</div>
        </div>

        <div className="flex items-center justify-center w-full lg:justify-start">
          <img src={horizontalLine} alt="horizontal line" className="w-1/2" />
        </div>
      </div>
    </div>
  );
};
export default Aboutus;
